<template>
  <div class="rural-details">
    <Header :currentIndex="'5'"></Header>
    <Breadcrumb :navTitle="'乡村振兴 > 正文'"></Breadcrumb>
    <div class="details-content">
      <div class="details-left">
        <div class="left-top">
          <span>{{ itemObj.info.title }}</span>
          <div>
            <span
              >{{ itemObj.info.createtime }} 来源:
              {{ itemObj.info.source }}</span
            >
            <span>浏览量：{{ itemObj.info.clicknum }}</span>
          </div>
        </div>
        <div>
          <span v-html="itemObj.info.content"></span>
        </div>
        <div>
          <span>相关资讯 </span>
          <div>
            <div
              v-for="item in itemObj.xiangguangList"
              :key="item.id"
              @click="goHotDetails(item.id)"
            >
              <span>{{ item.title }}</span>
              <span>{{ item.createtime }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="details-right">
        <div class="right-top">
          <span>资本热点</span>
        </div>

        <div
          class="list-item"
          v-for="item in itemObj.tuiList"
          :key="item.id"
          @click="goHotDetails(item.id)"
        >
          <div>
            <img :src="BaseUrl + item.image" alt="" />
            <span :title="item.title">{{ item.title }}</span>
          </div>
          <div>
            <span>{{ item.brief }}</span>
            <span>[详细]</span>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "../../../../components/Header.vue";
import Breadcrumb from "../../../../components/Breadcrumb.vue";
import Footer from "../../../../components/Footer.vue";
export default {
  name: "RuralDetails",
  data() {
    return {
      index: 5,
      id: "",
      itemObj: {},
    };
  },
  created() {
    console.log(this.$route.params.id);
    this.id = this.$route.params.id;
    this.getDetails();
  },
  methods: {
    getDetails(id) {
      this.$http({
        url: "/lilun/detail",
        params: {
          id: id ? id : this.id,
        },
      }).then((res) => {
        console.log(res, "details");
        this.itemObj = res.data;
      });
    },
    //跳转热点详情
    goHotDetails(id) {
      this.$router.push(`/hotdetails/${id}`);
    },
    // 相关资讯跳转
    goRuralDetails(id) {
      this.$router.push(`/ruraldetails/${id}`);
    },
  },
  components: {
    Header,
    Breadcrumb,
    Footer,
  },
};
</script>

<style lang="less" scoped>
.rural-details {
  .details-content {
    display: flex;
    width: 1200px;
    margin: 0px auto -80px;
    .details-left {
      width: 844px;
      margin: 40px 45px 0 0;
      text-align: center;
      overflow: hidden;
      .left-top {
        width: 844px;
        padding-bottom: 42px;
        margin-bottom: 29px;
        border-bottom: 1px solid #e5e5e5;
        > span:nth-child(1) {
          font-size: 26px;
          color: #333;
        }
        > div:nth-child(2) {
          font-size: 16px;
          color: #666666;
          margin-top: 21px;
          > span {
            margin-right: 15px;
          }
        }
      }
      > div:nth-child(2) {
        font-size: 17px;
        color: #666666;
        line-height: 35px;
        margin-bottom: 60px;
      }
      > div:nth-child(3) {
        width: 844px;
        text-align: left;
        > span:nth-child(1) {
          display: block;
          width: 112px;
          padding-bottom: 11px;
          margin-bottom: 18px;
          font-size: 24px;
          color: #12407d;
          border-bottom: 2px solid #12407d;
        }
        > div:nth-child(2) {
          width: 844px;
          > div {
            display: flex;
            justify-content: space-between;
            margin-bottom: 11px;
            font-size: 16px;
            color: #666666;
            > span:nth-child(1)::before {
              display: inline-block;
              content: "";
              width: 4px;
              height: 4px;
              background: #12407d;
              margin: 0 6px 3px 0;
            }
          }
        }
      }
    }
    .details-right {
      width: 311px;
      .right-top {
        width: 311px;
        padding-bottom: 20px;
        margin-bottom: 30px;
        border-bottom: 2px solid #a6a6a6;
        text-align: left;
        > span {
          width: 72px;
          font-size: 17px;
          padding-bottom: 20px;
          color: #2050ac;
          border-bottom: 2px solid #12407d;
        }
      }
      .list-item {
        margin-bottom: 40px;
        > div:nth-child(1) {
          position: relative;
          width: 311px;
          height: 234px;
          > img:nth-child(1) {
            position: absolute;
            width: 311px;
            height: 234px;
          }
          > span:nth-child(2) {
            width: 311px;
            height: 40px;
            position: absolute;
            bottom: 0;
            text-align: center;
            line-height: 40px;
            background: rgba(100, 100, 100, 0.33);
            color: #ffffff;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            text-indent: 0.5em;
          }
        }
        > div:nth-child(2) {
          margin-top: 18px;
          font-size: 13px;
          color: #666666;
          > span:nth-child(2) {
            font-size: 16px;
            color: #2050ac;
          }
        }
      }
    }
  }
}
</style>
